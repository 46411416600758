import { RequestMainService } from "../request/requestMain.service";

export class StartupService {
  static getStarupList(param: any) {
    return RequestMainService.get("/admin/startups", param).then(({ result }) =>
      // return RequestMainService.get("/startups", param).then(({result}) => (
      ({
        data: result.data,
        count: result.total,
      })
    );
  }

  static getStartupInvestmentHistory(param: any) {
    return RequestMainService.get("/admin/startups/investment-histories", param).then(({ result }) => ({
      data: result.data,
      count: result.total,
    }));
  }

  static updateStartup(payload: any) {
    return RequestMainService.post(`/admin/startup/edit-startup`, payload);
  }

  static updateStartupStatus(payload: any) {
    return RequestMainService.post(`/admin/startup/edit-status-startup`, payload);
  }

  static updateStartupActiveStatus(payload: any) {
    return RequestMainService.post(`/admin/startup/edit-active-status-startup`, payload);
  }

  static startupApprovalForCallingCapitalList(payload: any) {
    return RequestMainService.get(`/admin/startups/in-review`, payload).then(({ result }) => ({
      data: result?.data || [],
      count: result?.total || 0,
    }));
  }

  static approveCallingCapitalStartup(id: any) {
    return RequestMainService.put(`/admin/startups/in-review/${id}/decision`, {action: "APPROVE", reason: ""})
  }

  static contactStartupList(params: any) {
    return RequestMainService.get(`/admin/startups/investments/all-contract`, params).then(({ result }) => ({
      data: result?.data || [],
      count: result?.total || 0,
    }));
  }
  static getStartupInvestorList(param: any) {
    return RequestMainService.get("/admin/get-investment-of-startup-by-id", param).then((res) => {
      return {
        data: res?.result?.data || [],
        count: res?.result?.total || 0,
      }
    })
  }

  static getStartupProjectTypeList() {
    return RequestMainService.get("/get-agency-types").then((res) => {
      return {
        data: res?.result || [],
        count: 0,
      }
    })
  }
}
